export default {
    SHANHU_LIVE_API: 'https://live.shkt.online',
    SHANHU_DATA_API: 'https://data.shkt.online',
    SHANHU_ONLINE_API: 'https://school.shkt.online',
    SHANHU_LIVE_HY_API: 'https://livehy.shkt.online'

    // SHANHU_LIVE_API: 'https://preview_live.shkt.online',
    // SHANHU_DATA_API: 'https://preview_data.shkt.online',
    // SHANHU_ONLINE_API: 'https://preview_online.shkt.online',
    // SHANHU_LIVE_HY_API: 'https://prehy_live.shkt.online',
}
